<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div class="flex h-full flex-col bg-white p-5 lg:max-w-screen-xxs">
    <header class="flex items-center gap-2.5 border-b border-blue-450 pb-4 text-lg font-bold leading-4 text-blue">
      <UiIcon
        name="tabler-icon-category"
        class="text-grey"
        width="22"
        height="22"
      />
      <HtmlParser
        v-if="props.header"
        :html="props.header"
      />
    </header>
    <ul
      class="custom-scrollbar custom-scrollbar--thin flex flex-1 flex-col gap-3 overflow-x-auto py-4 sm:gap-1"
      :class="[{ 'max-h-[478px] overflow-y-scroll xxs:max-h-[468px] sm:max-h-[370px]': Array.isArray(categories) && categories.length > 11 }]"
    >
      <li
        v-for="category in categories"
        :key="category?.uid"
        class="px-2.5 "
      >
        <NuxtLink
          :to="parseCategoryLink(category?.canonical_url ?? '')"
          class="flex items-center justify-between py-1.5 text-base font-normal leading-4 text-blue"
        >
          {{ category?.name }}

          <UiIcon
            name="arrow"
            height="14"
            class="block text-grey sm:hidden"
          />
        </NuxtLink>
      </li>
    </ul>

    <div
      v-if="props.categoryLink?.href && categories && categories.length > 11"
      class="border-t border-blue-450 px-2.5 pt-4"
    >
      <NuxtLink
        :to="props.categoryLink.href"
        class="group flex items-center gap-2 text-base font-bold leading-5 text-blue underline-offset-4 transition hover:underline"
      >
        {{ t('all_categories') }}
        <UiIcon
          name="arrow"
          width="16"
          height="16"
          class="transition group-hover:translate-x-2"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useAppNav } from '@base/components/AppNav/useAppNav'
import type { MagentoCategoryLink } from '@cms/types/MagentoCategory.types'

const props = defineProps<{
  header?: string
  categoryLink?: MagentoCategoryLink
}>()

const { t } = useI18n()
const { getCategoryList } = useCatalogQueries()
const { categoryLink: parseCategoryLink } = useAppNav()

const { data: categories } = await useAsyncData(() => {
  const uid = props.categoryLink?.magentoCategory?.uid
  return getCategoryList({ parentUid: uid })
}, { transform: data => data.categories?.items?.filter(category => Boolean(category?.include_in_menu)) })
</script>
